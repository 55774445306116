import React from 'react';
import { ICoreContext } from '@msdyn365-commerce/core';
import { IOrderDetailResponse } from '../../../actions/get-order-details.action';
import { IOrderDetailsResources } from '../order-details.props.autogenerated';
import { formatDate, formatTime } from '../../../utilities';
import { NO_DATA_FALLBACK } from '../../../configuration';
import DocumentDownloadItem from '../components/document-download-item';

export default function documentsTable({
    context,
    orderDetails,
    resources
}: {
    context: ICoreContext;
    orderDetails: IOrderDetailResponse;
    resources: IOrderDetailsResources;
}) {
    return (
        <>
            <h3 className='ms-order-details__section-heading'>{resources.orderDetailsDocumentsTableHeading}</h3>
            {orderDetails.Documents?.length ? (
                <div className='table-responsive'>
                    <table className='table'>
                        <thead>
                            <tr>
                                <th>{resources.orderDetailsDocumentsTabeleDocumentName}</th>
                                <th>{resources.orderDetailsDocumentsTableDate}</th>
                                <th>{resources.orderDetailsDocumentsTableLanguage}</th>
                                <th>{resources.orderDetailsDocumentsTableReference}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {orderDetails.Documents?.map((documentItem, index) => (
                                <tr key={index}>
                                    <td>
                                        <DocumentDownloadItem
                                            context={context}
                                            resources={resources}
                                            documentItem={documentItem}
                                            index={index}
                                        />
                                    </td>
                                    <td>
                                        {documentItem.Date ? formatDate(context, new Date(documentItem.Date)) : NO_DATA_FALLBACK}{' '}
                                        {documentItem.Date ? formatTime(context, new Date(documentItem.Date)) : NO_DATA_FALLBACK}
                                    </td>
                                    <td>{documentItem.Language ?? NO_DATA_FALLBACK}</td>
                                    <td>{documentItem.Reference ?? NO_DATA_FALLBACK}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            ) : (
                <p className='ms-order-details__documents-placeholder'>{resources.orderDetailsDocumentsEmpty}</p>
            )}
        </>
    );
}
